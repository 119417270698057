import { make } from 'vuex-pathify'


const state = {
  currentList: [],
  currentReport: {
    settings: {},
  }

}



export default {

  namespaced: true,


  state,
  actions: make.actions(state),
  mutations: {
    ...make.mutations(state),
    "currentReport.settings": (state, data) => {
      state.currentReport.settings = data
    },
    "currentReport.settings.subjects": (state, data) => {
      if (!state.currentReport.settings) {
        state.currentReport.settings = {}
      }
      state.currentReport.settings.subjects = data
    },
    "currentReport.settings.years": (state, data) => {
      if (!state.currentReport.settings) {
        state.currentReport.settings = {}
      }
      state.currentReport.settings.years = data
    },
    "currentReport.description": (state, data) => {
      state.currentReport.description = data
    },
    "currentReport.title": (state, data) => {
      state.currentReport.title = data
    },
    "currentReport.due_date": (state, data) => {
      state.currentReport.due_date = data
    },
    "currentReport.changes": (state, data) => {
      state.currentReport.changes = data
    },
  },
  getters: {
    ...make.getters(state),
    "currentReport.description": (state) => {
      return state.currentReport?.description
    },
    "currentReport.title": (state) => {
      return state.currentReport?.title
    },
    "currentReport.due_date": (state) => {
      return state.currentReport?.due_date
    },


  }







}





