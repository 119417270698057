export default
    {
        path: "/school/datadump/manage",
        name: "schoolDataDumpManage",
        component: () => import("Views/School/DataDump/Manage.vue"),
        meta: {
            requiresAuth: true,
            requiresDomain: true,
            requiresStaff: true,
            requiresAccessLevel: 25,
        },
        redirect: { name: "schoolDataDump.list" },
        children: [
            {
                path: "list",
                name: "schoolDataDump.list",
                component: () => import("Views/School/DataDump/List.vue"),
                meta: {
                    requiresAuth: true,
                    requiresDomain: true,
                    requiresStaff: true,
                    requiresAccessLevel: 25,
                },
            },
            {
                path: "/school/datadump/create",
                name: "schoolDataDump.create",
                component: () => import("Views/School/DataDump/CreateNew.vue"),
                meta: {
                    requiresAuth: true,
                    requiresDomain: true,
                    requiresStaff: true,
                    requiresAccessLevel: 25,
                },
            },

            {
                path: "/school/datadump/manage/:id",
                name: "schoolDataDump.view",
                component: () => import("Views/School/DataDump/View.vue"),
                redirect: { name: "schoolDataDump.view.summary" },
                meta: {
                    requiresAuth: true,
                    requiresDomain: true,
                    requiresStaff: true,
                    requiresAccessLevel: 25,
                },
                children: [   //
                    {
                        path: "/school/datadump/manage/:id/students",
                        name: "schoolDataDump.view.students",
                        component: () => import("Views/School/DataDump/View/Students.vue"),
                        meta: {
                            requiresAuth: true,
                            requiresDomain: true,
                            requiresStaff: true,
                            requiresAccessLevel: 25,
                        },
                    }, {
                        path: "/school/datadump/manage/:id/managestudents",
                        name: "schoolDataDump.view.manage.students",
                        component: () => import("Views/School/DataDump/View/ManageStudents.vue"),
                        meta: {
                            requiresAuth: true,
                            requiresDomain: true,
                            requiresStaff: true,
                            requiresAccessLevel: 25,
                        },
                    }, {
                        path: "/school/datadump/manage/:id/staff",
                        name: "schoolDataDump.view.staff",
                        component: () => import("Views/School/DataDump/View/Staff.vue"),
                        meta: {
                            requiresAuth: true,
                            requiresDomain: true,
                            requiresStaff: true,
                            requiresAccessLevel: 25,
                        },
                    },
                    {
                        path: "/school/datadump/manage/:id/summary",
                        name: "schoolDataDump.view.summary",
                        component: () => import("Views/School/DataDump/View/SummaryView.vue"),
                        meta: {
                            requiresAuth: true,
                            requiresDomain: true,
                            requiresStaff: true,
                            requiresAccessLevel: 25,
                        },
                    },

                ]
            },
        ],
    }