import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import store from "Plugins/store";
import { saveStudent } from 'Classes/student'
import { saveGroup } from 'Classes/group'

import GitCommit from "../_git_commit";

const ls = require("local-storage");

import { memoPromise } from "Helpers/memopromise.js";
import { ulid } from "ulid";


const memoizedFetch = memoPromise(fetch, 5000)


export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

const state = {
  currentWebSocket: null,
  rejoining: false,
  lastConnectionAttempt: 0,
  join: false,
  connected: false,
  _pendingRequests: {},
  _lastRequestId: 0,
  instanceID: Math.floor(Math.random() * 1000000),
}

const api = {

  baseUrl: process.env.VUE_APP_API_BASE,

  async switchBase(mode = "production") {
    console.log("Switching base:", mode, process.env.VUE_APP_STAGE)
    if (mode == "dev") {
      this.baseUrl = process.env.VUE_APP_API_BASE_DEV
      state.join = (process.env.VUE_APP_STAGE != "production")

    }
    else {
      this.baseUrl = process.env.VUE_APP_API_BASE
      state.join = (process.env.VUE_APP_STAGE == "production")
    }

    console.log({ state })
  },


  async post(url, accessToken, domain = false, data = {}, headers = {}) {
    let localHeaders = {
      ...headers,
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };

    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false);
      if (!accessToken) {
        return {};
      }
      localHeaders["Authorization"] = `Bearer ${accessToken}`;
    }

    if (domain) {
      localHeaders["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })
      let ablyClientID = store.state.realtime?.auth?.clientId ?? undefined

      if (ablyClientID) {
        localHeaders["X-Ably-Client-ID"] = ablyClientID
      }

      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    console.log(localHeaders);

    // Default options are marked with *
    const response = await fetch(this.baseUrl + url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: localHeaders,
      redirect: "follow", // manual, *follow, error
      //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  },

  async get(url, accessToken, domain = false, headers = null, memod = false) {
    console.log(["GET", { url, accessToken, domain, headers, memod }]);

    if (!headers) {
      headers = {}
    }

    headers = {
      ...headers,
      "Content-Type": "application/json",
      "X-API-Version": 2,
    }

    console.log("Headers now:", headers)


    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false);
      if (!accessToken) {
        return {};
      }
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })
      let ablyClientID = store.state.realtime?.auth?.clientId ?? undefined

      if (ablyClientID) {
        headers["X-Ably-Client-ID"] = ablyClientID
      }

      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    const fetcher = (memod ? memoizedFetch : fetch)

    console.log(memod ? "Using memo" : "Not using memo", url)

    try {
      // Default options are marked with *
      const response = await fetcher(this.baseUrl + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      return response.clone().json(); // parses JSON response into native JavaScript objects
    } catch (e) {
      return undefined;
    }
  },

  async sendMessageOverWS(message) {
    if (!state.currentWebSocket) {
      console.log("No websocket to send message over");
      return;
    }
    state.currentWebSocket.send(message);

  },


  /*

  const id = ulid()


    */
  requestWS(command, data, defaultAction = undefined, blocking = false) {

    if (!state.currentWebSocket || !state.connected) {
      console.log("No WS connection")
      if (defaultAction !== undefined) {
        return defaultAction
      }
      return new Promise((_resolve, reject) => { reject(new Error("No ws connction")) })
    }

    /* Unless we send petabytes of data using the same socket,
        we won't worry about `_lastRequestId` getting too big. */
    const wrapper = {
      command,
      data,
      id: ++state._lastRequestId,
      instanceID: state.instanceID,
      type: "ws-request-response",

    }

    console.log("wrapper: Sending request", wrapper)

    if (blocking) {
      store.dispatch("setLoadingFull", "WSWrapper: " + wrapper.id)
    }

    // Return a Promise to the caller to be resolved later
    const request = new Promise((resolve, reject) => {
      const pendReq = (state._pendingRequests[wrapper.id] = {
        resolve,
        reject,
        timer: null,
        blocking
      })
      if ((state._requestTimeout ?? 0) > 0) {
        pendReq.timer = setTimeout(() => {
          reject(new Error("Request timed out"))
          delete state._pendingRequests[wrapper.id]
        }, state._requestTimeout)
      }
    })

    state.currentWebSocket.send(JSON.stringify(wrapper))
    return request

  },


  async getNewAccessToken(force = false) {


    Object.keys(localStorage).filter(x => x.startsWith("newToken:")).forEach(x => {
      if (x != "newToken:" + GitCommit.version) {
        console.log("Removing old token", x)
        ls.remove(x)
      }
    })


    console.log("Getting new access token", force)
    let x

    if (!force) {
      x = await ls("newToken:" + GitCommit.version)

      if ((x?.decoded?.exp ?? 0) > Math.floor(Date.now() / 1000)) {
        store.set("user/permissions", x.decoded.permissions)
        console.log({ CCCCCCCCCCCCCC: x.decoded })
        store.dispatch("setWsID", x.decoded.permissions.wsId)
        store.dispatch("updateReferenceData", false);
        connectToWS()
        return x
      }

      x = null
    }

    ls.remove("newToken:" + GitCommit.version)

    if (!x) {
      store.dispatch("setLoadingFull", "auth/swap")



      x = await api.get("auth/swap", true, true, {
        'X-Fcmtoken': store.state.fcmToken
      }, true)

      if (!x?.jwt) {
        store.dispatch("clearLoadingFull", "auth/swap")
        store.set("user/permissions", {})
        return null
      }
    }

    console.log({ newX: x })

    const domain = store.state.currentDomain;

    if (!domain) {
      store.dispatch("clearLoadingFull", "auth/swap")
      store.set("user/permissions", {})
      return null
    }

    const temp = parseJwt(x.jwt)
    console.log(temp)
    if (!temp.aud && temp.aud != domain) {
      store.dispatch("clearLoadingFull", "auth/swap")
      store.set("user/permissions", {})
      return null
    }

    x = {
      decoded: temp,
      jwt: x.jwt
    }


    console.log("I am about to store", x)

    ls("newToken:" + GitCommit.version, x)




    store.set("user/permissions", temp.permissions)


    store.dispatch("setWsID", temp.permissions.wsId)
    connectToWS()
    store.dispatch("clearLoadingFull", "auth/swap")
    store.dispatch("updateReferenceData", true);
    return x

  },



  async get3(url) {
    const id = ulid()
    store.dispatch("setLoadingFull", id)
    let rtn = await api.get2(url, true)
    store.dispatch("clearLoadingFull", id)
    return rtn
  },

  async get2(url, domain = false) {
    console.log(["GET", url, domain, store]);

    let headers = {
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };


    let aT = await api.getNewAccessToken()

    let accessToken = aT?.jwt

    if (!accessToken) {
      console.log("I don't have an access token (new api get2)")
      return false;
    }

    headers["Authorization"] = `Bearer X_${accessToken}`;

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })


      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    try {
      // Default options are marked with *
      const response = await fetch(api.baseUrl + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      return response.json(); // parses JSON response into native JavaScript objects
    } catch (e) {
      return undefined;
    }
  },


  async post3(url, data = {}) {
    const id = ulid()
    store.dispatch("setLoadingFull", id)
    try {
      let rtn = await api.post2(url, true, data)
      store.dispatch("clearLoadingFull", id)
      return rtn
    } catch (e) {
      store.dispatch("clearLoadingFull", id)
      return undefined
    }
  },


  async post2(url, domain = false, data = {}) {
    console.log(["POST", url, domain]);

    let headers = {
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };

    let aT = await this.getNewAccessToken()

    let accessToken = aT?.jwt

    if (!accessToken) {
      return {};
    }

    headers["Authorization"] = `Bearer X_${accessToken}`;

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })


      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    try {
      // Default options are marked with *
      const response = await fetch(this.baseUrl + url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        body: JSON.stringify(data),
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      return response.json(); // parses JSON response into native JavaScript objects
    } catch (e) {
      return undefined;
    }
  },

  async getWithEtag(url, accessToken, domain = false, etag = null) {
    console.log(["GET (etag)", url, accessToken, domain, etag]);

    let headers = {
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };

    if (etag) {
      headers["If-None-Match"] = etag;
    }

    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false);
      if (!accessToken) {
        return {};
      }
      headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;

      console.log({ realtime: store.state.realtime })
      let ablyClientID = store.state.realtime?.auth?.clientId ?? undefined

      if (ablyClientID) {
        headers["X-Ably-Client-ID"] = ablyClientID
      }

      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    try {
      // Default options are marked with *
      const response = await fetch(this.baseUrl + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (response.status == 304) {
        return {
          status: 304,
        };
      }

      console.log(response);

      response.headers.entries((x) => {
        console.log([x]);
      });

      let etag = response.headers.get("etag");

      console.log("Got an etag of: ", etag);

      return {
        etag: etag,
        status: response.status,
        data: await response.json(), // parses JSON response into native JavaScript objects
      };
    } catch (e) {
      return undefined;
    }
  },

  async getWithEtag2(url, domain = false, etag = null) {
    console.log(["GET (etag)", url, domain, etag]);

    let headers = {
      "Content-Type": "application/json",
      "X-API-Version": 2,
    };

    if (etag) {
      headers["If-None-Match"] = etag;
    }



    let aT = await this.getNewAccessToken()

    let accessToken = aT?.jwt

    if (!accessToken) {
      console.log("I don't have an access token (new api get2)")
      return false;
    }

    headers["Authorization"] = `Bearer X_${accessToken}`;

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
      console.log({ realtime: store.state.realtime })


      const wsID = store.state.wsID;

      if (wsID) {
        headers["X-WS-ID"] = wsID
      }
    }

    try {
      // Default options are marked with *
      const response = await fetch(this.baseUrl + url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (response.status == 304) {
        return {
          status: 304,
        };
      }

      console.log(response);

      response.headers.entries((x) => {
        console.log([x]);
      });

      let etag = response.headers.get("etag");

      console.log("Got an etag of: ", etag);

      return {
        etag: etag,
        status: response.status,
        data: await response.json(), // parses JSON response into native JavaScript objects
      };
    } catch (e) {
      return undefined;
    }
  },



};

export const getWS = () => {

  if (state.connected) {
    return state.currentWebSocket;
  }
  return null
}



setInterval(() => {
  if (state.currentWebSocket) {
    state.currentWebSocket.send("X-Clacks-Overhead")
  }

}, 30000);

export const connectToWS = async () => {
  console.log({ currentMode: process.env.VUE_APP_STAGE })

  if ((process.env.VUE_APP_STAGE ?? "production") != "production") {
    return;
  }
  state.join = true;
  checkWSConnection()
}


export const checkWSConnection = () => {
  //console.log("In interval check....", { join: state.join, wsID: store.state.wsID })
  if (!state.join || !store.state.wsID) {
    return;
  }
  if (state.currentWebSocket) {
    return
  }
  if (state.rejoining) {
    console.log("Websocket already rejoining.....")
    return;
  }
  if (state.lastConnectionAttempt > Date.now() - 10000) {
    console.log("Websocket connection attempted in last 10 seconds")
    return;
  }

  state.lastConnectionAttempt = Date.now();
  state.rejoining = true;



  // If we are running via wrangler dev, use ws:
  const wss = (api.baseUrl).replace("http", "ws")
  let ws = new WebSocket(wss + "ws/websocket");











  ws.addEventListener("open", _event => {
    state.currentWebSocket = ws;
    state.rejoining = false;

    console.log("WebSocket opened");

    console.log("Sending auth message", store.state.wsID)
    // Send user info message.
    ws.send(JSON.stringify({ auth: store.state.wsID }));

  });






  ws.addEventListener("message", event => {




    if (event.data == "GNU Terry Pratchett") {
      return;
    }
    let data = JSON.parse(event.data);


    if (data['ws-request-response']) {

      if (data.id && state._pendingRequests[data.id]) {

        console.log("wrapper: Processing response for request", data.id)
        const pendReq = state._pendingRequests[data.id]
        console.log({ pendReq })
        clearTimeout(pendReq.timer ?? 0)
        if (pendReq.blocking) {
          store.dispatch("clearLoadingFull", "WSWrapper: " + data.id)
        }
        pendReq.resolve({
          data: data.response,
          status: data.status
        })
        delete state._pendingRequests[data.id]
        return
      }

      if (data.id) {
        console.log("wrapper: No pending request for response", data.id)
        return
      }

      console.log("wrapper: No id for response", data)
    }


    switch (data.type ?? "unknown") {

      case "status":
        console.log("I am now connected :-)")
        state.connected = data.ready ?? false
        store.set("connected", data.ready ?? false)
        break



      case "student":
        console.log("This is a student")
        saveStudent(store.state.currentDomain, data.data)
        break

      case "group":
        console.log("This is a group")
        saveGroup(store.state.currentDomain, data.data)
        break;



      default:
        if (data.ack) {
          console.log("Got an ack")
          break;
        }


        console.log({ err: "Unknown message type", data })
        break;
    }

  });

  ws.addEventListener("close", event => {
    store.set("connected", false)
    state.currentWebSocket = null;
    state.connected = false;
    state.rejoining = false;
    console.log("WebSocket closed, reconnecting:", event.code, event.reason);

  });


  ws.addEventListener("error", event => {
    store.set("connected", false)
    state.currentWebSocket = null;
    state.rejoining = false;
    state.connected = false;
    console.log("WebSocket error, reconnecting:", event);

  });



}

setInterval(() => checkWSConnection()
  , 1000);





export default api
