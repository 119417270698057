import format from "date-fns/format";
import store from "Plugins/store";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";
import parseISO from "date-fns/parseISO";
import { memoPromise } from "Helpers/memopromise.js";

import { /*get as localKVget,*/ get2 as localKVget2, save } from "Helpers/localKV";

const memoizedFetchAll = memoPromise(localKVget2, 10000)


export const saveStudent = async (domain, studentData, studentID) => {

  if (!studentData._id) {
    studentData._id = studentID;
  }


  studentData.attendance2 = Object.values(studentData.attendance ?? []).reduce((acc, val) => {

    return { ...acc, ...val }
  }, {})

  await save(domain, "student", studentData);

  await store.commit("setCurrentGroupStudent", studentData)

};


export default class Student {
  constructor() { }

  getPhoto() {
    return (
      this.photo ||
      "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBg+A8AAQQBAHAgZQsAAAAASUVORK5CYII="
    );
  }

  gender() {
    return this.basic?.Gender;
  }

  dob(formatted = false) {
    if (!this.basic) {
      return null;
    }
    if (formatted) {
      return format(parseISO(this.basic.DOB), "do LLL yyyy");
    }
    return this.basic.DOB;
  }

  age() {
    if (!this.basic) {
      return null;
    }
    let age = intervalToDuration({
      start: new Date(this.basic.DOB),
      end: new Date(),
    });
    return formatDuration(age, { format: ["years", "months"], delimiter: "," });
  }

  tutor() {
    return this.basic.Class ?? this.Class ?? this.allData?.Class ?? this.basic.ClassName ?? ""
  }

  house() {
    return this.basic?.House;
  }

  teachers() {
    return this.teachers;
  }

  candidateNumber() {
    let x = this.SEND ?? this.allData?.SEND;
    return x?.["Candidate number"] ?? "TBC";
  }

  name(firstNameFirst = true) {
    if (!this.basic) {
      return "";
    }

    let fn = this.basic.FirstName ?? "?";
    let sn = this.basic.LastName ?? "?";
    if (firstNameFirst) return fn + " " + sn;
    return sn + ", " + fn;
  }

  firstName() {
    return this.FirstName;
  }

  parentPhoneNumbers() {
    return (
      this.parents?.phoneNumbers ?? this.allData?.parents?.phoneNumbers ?? []
    );
  }

  parentSalutation() {
    return this.parents?.Salutation ?? this.allData?.parents?.Salutation ?? [];
  }

  accessArrangements() {
    return (
      this.SEND?.["Access Arrangements"] ??
      this.allData.SEND["Access Arrangements"]
    );
  }

  getAttributes() {
    return Object.keys(this);
  }

  getMEGS() {
    return this.MEGS;
  }

  getAlerts() {
    return this.ALERT ?? this.alerts;
  }

  getID() {
    return this.PupilID;
  }

  static async get(studentID) {
    return await localKVget2("student", studentID, new Student(), [
      "student/" + studentID,

    ]);
  }
  static async list(_referenceData = null) {
    let response = await memoizedFetchAll("AllStudents", null, null, [
      "student/search",


    ])
    return response;
  }


  static async getStudent(studentID) {

    let response = await this.list() ?? {}

    if (!response?.students) {
      return undefined
    }

    let student = response.students.find(x => x.PupilID == studentID)





    return student

  }
}
