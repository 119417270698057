<template>
  <div class="modal" :class="showModalFlag ? 'is-active' : ''">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Search for {{ searchFor }}</p>
        <button class="delete" aria-label="close" v-on:click="cancelModal()">
          >
        </button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Enter Search Term</label>
          <input class="input" ref="theSearchTerm" type="text" v-model="searchTerm" />
        </div>
        <span style="
            display: flex;
            justify-content: flex-end;">Showing {{ getListForSearch?.values?.length ?? 0 }} of {{
              getListForSearch.count
            }}</span>
        <table class="table is-striped  is-fullwidth">

          <tr v-for="line in getListForSearch?.values ?? []" :key="line.key">

            <td>{{ line.FirstName }}</td>
            <td>{{ line.LastName }}</td>
            <td>{{ line.Class }} {{ line.Type }}</td>
            <td v-if="showEmail">{{ line.Email }}</td>
            <td style="text-align: right;">
              <button @click="showPerson(line)" class="button">{{ buttonText }}</button>

            </td>

          </tr>
        </table>

      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" v-on:click="okModal()">Ok</button>
        <button v-if="showCancel" class="button" v-on:click="cancelModal()">
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>

import { bus } from "Plugins/addressbus.js"
import Student from "Classes/student"
import Staff from "Classes/staff"
export default {
  name: "componentsModal",

  data: function () {
    return {
      showModalFlag: false,
      okPressed: false,
      message: "",
      title: "",
      searchFor: "...",
      referenceData: null,
      showCancel: true,
      showOK: true,
      noOKAction: false,
      students: [],
      staff: [],
      searchTerm: "",
      mode: "navbar",
      showEmail: true,
      buttonText: "Show",
      emitKey: null,
      showFilter: [],
      clearOnButton: false,
      emitMode: "original"
    };
  },
  methods: {
    showPerson(line) {

      if (this.mode == "navbar") {

        if (line.Type == "Pupil") {
          this.$store.set("currentPupil!", line.ID)
          this.searchTerm = ""
          this.showModalFlag = false;
          return
        }
        alert("Sorry, not working yet")
        return

      }
      if (this.mode == "emit") {
        console.log("Shall i send new mode....", this.emitMode)
        if (this.emitMode == "object") {
          console.log({
            msg: "Sennding....", data: {
              type: line.Type.toLowerCase(),
              uid: line.ID, class: line.Class ?? (line.Type == "Staff" ? "Staff" : "?"),
              firstName: line.FirstName,
              lastName: line.LastName,
              title: line.Title
            }
          })
          bus.$emit(this.emitKey, {
            type: line.Type.toLowerCase(),
            uid: line.ID, class: line.Class ?? (line.Type == "Staff" ? "Staff" : "?"),
            firstName: line.FirstName,
            lastName: line.LastName,
            title: line.Title
          })


        }
        else {
          console.log("Sending onld mode")
          bus.$emit(this.emitKey, [line.Type.toLowerCase(), line.ID, line.Class ?? (line.Type == "Staff" ? "Staff" : "?"), line.FirstName + " " + line.LastName])

        }

        this.searchTerm = ""
        this.showModalFlag = false;
      }
      console.log(line)
    },
    showModalNew: function (message, title, options = {}) {

      console.log({ message, title, options })
      this.okPressed = false;
      this.showModalFlag = true;
      this.message = message;
      this.title = title;

      this.referenceData = options.referenceData ?? null;
      this.showCancel = options.showCancel ?? true;
      this.showOK = options.showOK ?? true;
      this.noOKAction = options.noOKAction ?? false
      this.mode = options.callingMode ?? "navbar"
      this.showEmail = options.showEmail ?? true
      this.emitKey = options.emitKey ?? null
      this.buttonText = options.buttonText ?? "Show"
      this.showFilter = options.show ?? [],
        this.searchFor = options.searchFor ?? "..."
      this.clearOnButton = options.clearOnButton ?? false
      this.emitMode = options.emitMode ?? "original"

      this.$nextTick(() => {
        this.$refs['theSearchTerm'].focus();
      })


    },
    showModal: function (message, title, options = null) {
      this.okPressed = false;
      this.showModalFlag = true;
      this.message = message;
      this.title = title;

      if (options == null) {
        options = {};
      } else if (typeof options === "string") {
        options = { referenceData: options };
      }

      this.referenceData = options.referenceData ?? null;
      this.showCancel = options.showCancel ?? true;
      this.showOK = options.showOK ?? true;
      this.noOKAction = options.noOKAction ?? false
      this.mode = options.callingMode ?? "navbar"
      this.showEmail = options.showEmail ?? true
      this.emitKey = options.emitKey ?? null
      this.buttonText = options.buttonText ?? "Show"
      this.showFilter = options.show ?? []
      this.searchFor = options.searchFor ?? "..."
      this.clearOnButton = options.clearOnButton ?? false
      this.emitMode = options.emitMode ?? "original"


      this.getStudents()
      this.getStaff();

      this.$nextTick(() => {
        this.$refs['theSearchTerm'].focus();
      })

    },
    okModal: function () {
      this.okPressed = true;
      if (!this.noOKAction) {
        this.$emit("okPressed", this.referenceData);
      }
      this.showModalFlag = false;
      this.searchTerm = ""
    },
    cancelModal: function () {
      this.okPressed = false;
      this.$emit("cancelPressed");
      this.showModalFlag = false;
      this.searchTerm = ""
    },
    async getStudents() {
      console.log("About to check students length")
      console.log({ students: this.students })
      if (this.students.length) {
        return this.students
      }
      console.log("Need to get student list....")
      let response = await Student.list()

      this.students = response.students.map((x) => {

        return {
          Type: "Pupil",
          key: "pupil/" + x.PupilID, ID: x.PupilID,
          FirstName: x.PrefName ?? x.FirstName,
          LastName: x.LastName,
          Class: x.Class,
          Email: x.email,
          Gender: x.Gender

        }
      })

      return this.students





    },
    async getStaff() {
      console.log("About to check staff length")
      console.log({ staff: this.staff })
      if (this.staff.length) {
        return this.staff
      }

      console.log("Getting the staff list")

      let response = await Staff.list()

      const temp = Object.entries(response ?? {})

      this.staff = []
      this.staff.length = temp.length


      temp.map((x) => {
        x[1].StaffID = x[0]
        return x[1]
      })

        .map((x) => {





          const rtn = {
            Type: "Staff",
            key: "staff/" + x.StaffID, ID: x.StaffID,

            FirstName: (x.PrefName?.length) ? x.PrefName : x.FirstName,
            LastName: x.LastName,
            Class: undefined,
            Email: x.email,
            Gender: x.Gender,
            Title: x.Title

          }

          return rtn


        }).forEach((x, y) => {
          this.$set(this.staff, y, x)
        })

      return this.staff





    },



  },
  computed: {
    valuesForCompare() {

      console.log("In valuesForCompare")

      let listFull = []

      if (this.showFilter.length == 0 || this.showFilter.includes("staff")) {
        listFull = [...listFull, ...this.staff]
      }

      if (this.showFilter.length == 0 || this.showFilter.includes("students")) {
        listFull = [...listFull, ...this.students]
      }



      console.log({ listFull })

      let list = listFull.sort((a, b) => {
        if (a.LastName == b.LastName) {
          if (a.FirstName == b.FirstName) {
            if (a.Class && b.Class) {
              return a.Class.localeCompare(b.Class);
            }
            if (a.Class) {
              return -1
            }
            return (b.Class) ? 1 : 0
          }
          return a.FirstName.localeCompare(b.FirstName);
        }
        return a.LastName.localeCompare(b.LastName);
      })
      return list
    },

    getListForSearch() {

      console.log("About to check getListForSearch length: [" + this.searchTerm + "]")
      if (this.searchTerm.length < 3) {
        return {
          count: 0,
          values: []
        }
      }

      const terms = this.searchTerm.split(" ").map(x => x.trim().toLowerCase()).filter(x => x)

      console.log("About to check terms length", terms)
      if (terms.length == 0) {
        return {
          count: 0,
          values: []
        }
      }

      let r = this.valuesForCompare;

      console.log({ r })


      const results = r.filter(x => {

        let found = {}

        for (let a of ["FirstName", "LastName", "Email", "Class"]) {
          for (let b of terms) {
            if (x[a] && x[a].toLowerCase().includes(b)) {
              found[b] = 1
            }
          }
        }

        return terms.length == Object.keys(found).length




      })

      console.log("About to check return1 length")
      return {
        count: results.length,
        values: results.slice(0, 5)
      }
    },






  },
  mounted() {

    this.getStudents()
    this.getStaff();



  }
};
</script>
