import { get as lsGet, set as lsSet } from "idb-keyval";

//import store from "Plugins/store";
import { encode, decode } from "@msgpack/msgpack";

import api from "Helpers/apicalls.js";

import GitCommit from "../_git_commit";



export const save = async (domain, objType, objectT, id = null, ttl = 300000) => {

  if (!objectT._id) {
    if (id) {
      objectT._id = id;
    }
    else {
      return false;
    }
  }
  else {
    id = objectT._id;
  }

  let key = domain + "/" + objType + "/" + id;



  const lObjectData = {
    _data: encode(objectT),
    _cacheExpiry: Date.now() + ttl,
    _version: GitCommit.version,
  };

  await lsSet(key, lObjectData);

  return true;
}


export const get = async (objType, id, objectT, getParams, ttl = 300000) => {
  let key = objType + "/" + (id ?? "");

  let lObject = await lsGet(key);

  if (
    !lObject ||
    !lObject._data ||
    lObject._cacheExpiry < Date.now() ||
    GitCommit.version != (lObject._version ?? "x")
  ) {
    lObject = await api.get(...getParams);

    lObject._id = id;

    const lObjectData = {
      _data: encode(lObject),
      _cacheExpiry: Date.now() + ttl,
      _version: GitCommit.version,
    };

    await lsSet(key, lObjectData);

    return objectT ? Object.assign(objectT, lObject) : lObject;
  }

  const data = decode(lObject._data);
  return objectT ? Object.assign(objectT, data) : data;
}


export const get2 = async (objType, id, objectT, getParams, ttl = 300000) => {
  let key = objType + "/" + (id ?? "");

  let lObject = await lsGet(key);

  if (
    !lObject ||
    !lObject._data ||
    lObject._cacheExpiry < Date.now() ||
    GitCommit.version != (lObject._version ?? "x")
  ) {
    lObject = await api.get3(...getParams);

    lObject._id = id;

    const lObjectData = {
      _data: encode(lObject),
      _cacheExpiry: Date.now() + ttl,
      _version: GitCommit.version,
    };

    await lsSet(key, lObjectData);

    return objectT ? Object.assign(objectT, lObject) : lObject;
  }

  const data = decode(lObject._data);
  return objectT ? Object.assign(objectT, data) : data;
}