export default {


    path: "/school/report/manage",
    name: "schoolReportManage",
    component: () => import("Views/School/Report/Manage.vue"),
    meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
        requiresAccessLevel: 25,
    },
    redirect: { name: "schoolReport.list" },
    children: [
        {
            path: "list",
            name: "schoolReport.list",
            component: () => import("Views/School/Report/List.vue"),
            meta: {
                requiresAuth: true,
                requiresDomain: true,
                requiresStaff: true,
                requiresAccessLevel: 25,
            },
        },
        {
            path: ":id",
            name: "schoolReport.view",
            component: () => import("Views/School/Report/View.vue"),
            meta: {
                requiresAuth: true,
                requiresDomain: true,
                requiresStaff: true,
                requiresAccessLevel: 25,
            },
            children: [   //
                {
                    path: "/school/report/manage/:id/students",
                    name: "schoolReport.view.students",
                    component: () => import("Views/School/Report/View/StudentSelection.vue"),
                    meta: {
                        requiresAuth: true,
                        requiresDomain: true,
                        requiresStaff: true,
                        requiresAccessLevel: 25,
                    },
                },
                {
                    path: "/school/report/manage/:id/basic",
                    name: "schoolReport.view.basic",
                    component: () => import("Views/School/Report/View/BasicInformation.vue"),
                    meta: {
                        requiresAuth: true,
                        requiresDomain: true,
                        requiresStaff: true,
                        requiresAccessLevel: 25,
                    },
                },
                {
                    path: "/school/report/manage/:id/fields",
                    name: "schoolReport.view.fields",
                    component: () => import("Views/School/Report/View/FieldsSelection.vue"),
                    meta: {
                        requiresAuth: true,
                        requiresDomain: true,
                        requiresStaff: true,
                        requiresAccessLevel: 25,
                    },
                }
            ]
        },

        {
            path: "create",
            name: "schoolReport.create",
            component: () => import("Views/School/Report/CreateNew.vue"),
            meta: {
                requiresAuth: true,
                requiresDomain: true,
                requiresStaff: true,
                requiresAccessLevel: 25,
            },
        },
    ],
}