var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"maz-base-component maz-phone-number-input maz-flex",class:[{ 'maz-is-dark': _vm.dark }, `maz-phone-number-input--${_vm.size}`],attrs:{"id":_vm.id}},[(!_vm.noCountrySelector)?_c('MhSelect',{ref:"CountrySelector",staticClass:"country-selector",class:{
      'no-padding-left': _vm.noFlags || !_vm.countryCode
    },attrs:{"value":_vm.countryCode,"options":_vm.countriesSorted,"placeholder":_vm.t.countrySelectorLabel,"search":!_vm.noSearch,"position":_vm.position,"search-placeholder":_vm.t.countrySelectorSearchPlaceholder,"items-height":_vm.countriesHeight,"error":_vm.shouldChooseCountry,"hint":_vm.shouldChooseCountry ? _vm.t.countrySelectorError : null,"size":_vm.size,"success":_vm.isValid && !_vm.noValidation,"disabled":_vm.disabled,"input-value":_vm.callingCode,"list-width":250,"config":{
      labelKey: 'dialCode',
      searchKey: 'name',
      valueKey: 'iso2'
    },"color":_vm.color},on:{"input":function($event){return _vm.setCountryCode($event, true)}},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return [_c('div',{staticClass:"maz-flex maz-align-center"},[(!_vm.noFlags)?_c('div',{staticClass:"country-selector__flag-container maz-mr-2"},[_c('div',{class:`maz-flag maz-flag-${option.iso2.toLowerCase()}`})]):_vm._e(),(_vm.showCodeOnList)?_c('span',{staticClass:"country-selector__calling-code maz-flex-fixed maz-text-muted",class:{
          'maz-text-muted-dark': option.isSelected
        }},[_vm._v(" +"+_vm._s(option.dialCode)+" ")]):_vm._e(),_c('div',{staticClass:"maz-dots-text maz-flex-1 maz-text-left maz-text-color",class:{
          'maz-text-white': option.isSelected
        }},[_vm._v(" "+_vm._s(option.name)+" ")])])]}}],null,false,1671068885)}):_vm._e(),(_vm.countryCode && !_vm.noFlags && !_vm.noCountrySelector)?_c('button',{staticClass:"maz-phone-number-input__country-flag",attrs:{"tabindex":"-1"},on:{"click":_vm.focusCountrySelector}},[_c('div',{class:`maz-flag maz-flag-${_vm.countryCode.toLowerCase()}`})]):_vm._e(),_c('MhInput',_vm._b({ref:"PhoneNumberInput",staticClass:"input-phone-number maz-flex-1",class:{
      'has-border-radius': _vm.noCountrySelector
    },attrs:{"id":_vm.uniqueId ? `${_vm.uniqueId}_phone_number` : null,"value":_vm.asYouTypeNumber,"placeholder":_vm.placeholder || _vm.t.phoneNumberLabel,"hint":_vm.hint || _vm.hintValue,"disabled":_vm.disabled,"size":_vm.size,"success":_vm.isValid && !_vm.noValidation,"clearable":"","color":_vm.color},on:{"keydown":_vm.onKeydown,"focus":function($event){return _vm.$emit('focus', $event)},"blur":_vm.onBlur,"change":function($event){return _vm.$emit('change', $event)},"clear":function($event){return _vm.$emit('clear', $event)},"input":_vm.buildResults}},'MhInput',_vm.$attrs,false),[_vm._t("icon-left",null,{"slot":"icon-left"}),_vm._t("icon-right",null,{"slot":"icon-right"})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }