var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"maz-base-component maz-list",class:{
    'maz-list--bordered': _vm.bordered,
    'maz-elevation': !_vm.noShadow,
    'maz-no-shadow': _vm.noShadow,
    'maz-is-dark': _vm.dark,
    'no-scroll': _vm.noScroll
}},[_c('div',{staticClass:"maz-flip-list"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }