import Vue from "vue";
import Router from "vue-router";
import store from "Plugins/store";
import SchoolRouter from "Plugins/routers/school.js";

import MyRouter from "Plugins/routers/my";

import HelpdeskRouter from "Plugins/routers/helpdesk";
import FormsRouter from "Plugins/routers/forms";
import LunchRouter from "Plugins/routers/lunch";

import { getCookie, setCookie } from "../helpers/functions"

const ls = require("local-storage");

Vue.use(Router);

const routes = {
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/login",
      redirect: "/auth",
    },

    {
      path: "/auth",
      name: "auth",
      component: () => import(/* webpackPrefetch: true */ "Views/Auth.vue"),
    },

    {
      path: "/verify",
      name: "verify",
      component: () => import("Views/EmailVerification.vue"),
      meta: {
        allowedUnverifiedEmail: true,
        requiresAuth: true,
      },
    },

    {
      path: "/interstitial",
      name: "interstitial",
      component: () => import(/* webpackPrefetch: true */ "Views/Interstitial.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/form",
      redirect: "/",
    },

    ...MyRouter,
    ...SchoolRouter,

    HelpdeskRouter,
    ...FormsRouter,
    ...LunchRouter,


    {
      path: "/visitor",
      name: "visitor",
      component: () => import("Views/Visitors/Visitor.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      },
    },
    {
      path: "/kiosk-checkin-visitor",
      name: "kiosk-checkin-visitor",
      component: () => import("Views/Checkins/Visitor.vue"),
      meta: {
        hideNavigationBar: true,
        kiosk: true,
        requiresDomain: true,
      },
    },
    {
      path: "/kiosk",
      name: "kiosk",
      component: () => import("Views/VisitorCheckin.vue"),
      meta: {
        hideNavigationBar: true,
        kiosk: true,
      },
    },

    {
      path: "/home",
      name: "schoolHome",
      component: () => import("Views/School/Home.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      },
    },

    {
      path: "/system/",
      name: "systemManager",
      component: () => import("Views/System/Manage.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
        requiresAccessLevel: 25,
      },
    },
  ],
};
console.log(routes);

const router = new Router(routes);

const mainMenu = document.getElementById("main-menu");
const burger = document.getElementById("example-burger");

router.beforeEach((to, from, next) => {

  const apiMode = getCookie('switchMode')
  if (apiMode) {
    router.app.$api.switchBase(apiMode)
  }

  const searchParams = new URLSearchParams(window.location.search);

  console.log({ searchParams })

  console.log(searchParams.entries().forEach(x => console.log(x)))


  if (searchParams.has('switchMode')) {
    console.log("Switching mode")

    if (searchParams.get('switchMode') === 'reset') {

      setCookie('switchMode', "dev", -30)
      router.app.$api.switchBase("production")
    }
    else {
      setCookie('switchMode', "dev", 30)
      router.app.$api.switchBase("dev")
    }
  }


  console.log("In before each,......")
  const hideNavigationBar = to.matched.some(
    (record) => record.meta.hideNavigationBar
  );

  if (to.fullPath.includes("%2F")) {
    next(to.fullPath.replace("%2F", "/"));
    return;
  }
  const kiosk = to.matched.some((record) => record.meta.kiosk);
  const currentUser = store.get("user/firebase");
  const currentUserEmailVerified = currentUser?.emailVerified ?? false;

  const requiresDepartment = to.matched.some(
    (record) => record.meta.requiresDepartment
  );
  const requiresAccessLevel = to.matched.some(
    (record) => record.meta.requiresAccessLevel
  );
  const requiresStaff =
    requiresDepartment ||
    requiresAccessLevel ||
    to.matched.some((record) => record.meta.requiresStaff);

  const requiresStudent = to.matched.some(
    (record) => record.meta.requiresStudent
  );

  const allowedUnverified = to.matched.some(
    (record) => record.meta.allowedUnverifiedEmail
  );
  const requiresDomain =
    requiresStaff ||
    requiresStudent ||
    to.matched.some((record) => record.meta.requiresDomain);
  const requiresAuth =
    requiresDomain ||
    requiresStaff ||
    requiresStudent ||
    to.matched.some((record) => record.meta.requiresAuth);

  const requiresDomains = to.matched.some(
    (record) => record.meta.requiresDomains
  );

  const allowedDomains = store.getters.domainsAllowed;

  const dropDowns = document.querySelectorAll("div.navbar-dropdown");

  dropDowns.forEach(function (el) {
    el.parentElement.classList.remove("is-hoverable");

    el.parentElement.blur();

    setTimeout(() => {
      el.parentElement.classList.add("is-hoverable");
    }, 200);
  });

  if (mainMenu) {
    mainMenu.classList.remove("is-active");
  }
  if (burger) {
    burger.classList.remove("is-active");
  }

  console.log("In router")

  if (hideNavigationBar) {
    store.commit("hideNavigationBar");
  } else {
    store.commit("showNavigationBar");
  }

  if (requiresAuth) {
    if (!currentUser) {
      store.commit("afterInterstitial", to.fullPath);
      next({ path: "/auth" });
      return;
    }

    if (!currentUserEmailVerified && !allowedUnverified) {
      store.commit("afterInterstitial", to.fullPath);
      next({ path: "/verify" });
      return;
    }
  }

  if (requiresDomain) {
    if (!store.state.currentUserDataNew.retrieved) {
      store.commit("afterInterstitial", to.fullPath);

      next({ path: "/interstitial?a=1" });
      return;
    }
    if (!store.state.currentDomain) {
      let d = ls("currentDomain") ?? {};

      if (
        !d ||
        typeof d !== "string" ||
        d == "" ||
        !allowedDomains.includes(d)
      ) {
        if (allowedDomains.length === 0) {
          next({ path: "/noassociated?a=1" });
          return;
        }

        if (allowedDomains.length !== 1) {
          next({ path: "/school/chooser" });
          return;
        }

        d = allowedDomains[0];
      }


      console.log("IN ROUTER - setting domain ", d)

      store.dispatch("setCurrentDomain", d);
    }
  }

  if (requiresDomains) {
    if (allowedDomains.length === 0) {
      next({ path: "/noassociated?a=2" });
      return;
    }
  }

  if (requiresStaff) {
    if (!store.getters.isStaff) {
      next({ path: "/home" });
      return;
    }
  }

  if (requiresStudent) {
    if (!store.getters.isStudent) {
      next({ path: "/home" });
      return;
    }
  }

  if (requiresDepartment) {
    let intersection = to.meta.requiresDepartment.filter((x) =>
      store.getters.currentUserDetails["DepartmentIds"].includes(x)
    );
    if (!intersection || intersection.length == 0) {
      next({ path: "/home" });
      return;
    }
  }

  if (requiresAccessLevel) {
    console.log({
      x25: store.getters.isAccessLevel(25),
      al: to.meta.requiresAccessLevel,
    });
    if (!router.app.$store.getters.isAccessLevel(to.meta.requiresAccessLevel)) {
      next({ path: "/home" });
      return;
    }
  }

  if (kiosk) {
    next();
    return;
  }
  next();
});

export default router;
