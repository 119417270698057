

import { /*get as localKVget,*/ get2 as localKVget2, save } from "Helpers/localKV";


export const saveGroup = async (domain, groupData, groupID) => {

  if (!groupData._id) {
    groupData._id = groupID;
  }



  await save(domain, "group", groupData);


};


export default class Group {
  constructor() { }





  static async get(groupID) {
    return await localKVget2("group", groupID, new Group(), [
      "group/" + groupID,

    ]);
  }

}
