import { get as lsGet, set as lsSet } from "idb-keyval";
import { encode, decode } from "@msgpack/msgpack";

import api from "Helpers/apicalls.js";

import GitCommit from "../_git_commit";


import { memoPromise } from "Helpers/memopromise.js";


const memoizedFetch = memoPromise(api.get2, 5000)

class ReferenceData {
  constructor() { }
}

const needRefresh = (referenceData, type) => {
  if (!referenceData || !referenceData._data) {
    console.log("No reference data");
    return true;
  }

  if (referenceData._type != type) {
    console.log("Reference data type mismatch");
    return true;
  }

  if (GitCommit.version != (referenceData._version ?? "x")) {
    console.log("Reference data version mismatch");
    return true;

  }
  if (referenceData._cacheExpiry < Date.now()) {

    console.log({ msg: "Reference data expired", expiry: referenceData._cacheExpiry, now: (Date.now()) + 1 });
    return true;
  }

  return false;

}

async function getReferenceData(type, force = false) {
  console.log("Getting referenceData: ", type);

  let referenceData = await lsGet("reference-data-" + type);

  if (!force && !needRefresh(referenceData, type)) {
    let referenceDataObj = Object.assign(
      new ReferenceData(),
      decode(referenceData._data)
    );

    return referenceDataObj;
  }


  referenceData = await memoizedFetch("school/reference/", true);

  if (!referenceData) {
    return {}
  }
  if (Object.keys(referenceData).length != 0) {



    const referenceDataO = {
      _data: encode(referenceData),
      _cacheExpiry: Date.now() + 6000000,
      _version: GitCommit.version,
      _type: type,
    };

    await lsSet("reference-data-" + type, referenceDataO);

    let referenceDataObj = Object.assign(new ReferenceData(), referenceData);
    return referenceDataObj;
  }
  else {
    return {}
  }
}





export { ReferenceData, getReferenceData };
