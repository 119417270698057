import format from "date-fns/format";

export const localisedYMD = (date = new Date()) => {

  const y = date.toLocaleDateString("en-GB", { timeZone: "Europe/London" }).split("/")

  return y[2] + "-" + y[1] + "-" + y[0]
}

export const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => func.apply(context, args), delay)
  }
}

export const tomorrow = (date = new Date()) => {

  let d = new Date(date);
  d.setDate(date.getDate() + 1);
  return d;

}

export const yesterday = (date = new Date()) => {

  let d = new Date(date);
  d.setDate(date.getDate() - 1);
  return d;

}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}



export const expand = (text, lookups) => {

  console.log("Expanding.....", text)

  if (lookups === undefined) {
    lookups = {}
  }


  lookups = { ...lookups, __current__date__: format(new Date(), "do LLL yyyy") }

  if ((text ?? "").trim().length === 0) {
    return text;
  }

  const regex = /\{([^ }]+)\}/g;

  let x = text.matchAll(regex);

  const matches = [...new Set([...x].map((x) => x[1]))];

  for (const match of matches) {

    console.log({ match, lookups, matched: lookups[match] })


    if (lookups[match] === undefined) {
      continue;
    }

    text = text.replaceAll("{" + match + "}", lookups[match] ?? "");
  }

  return text;
}

export const convertToMinutes = (time) => {
  let hours = parseInt(time[0], 10);
  let mins = parseInt(time[1], 10);

  return mins + hours * 60;
}


export default {


  debounce(fn, wait) {
    let timer;
    return function (...args) {
      if (timer) {
        clearTimeout(timer); // clear any pre-existing timer
      }
      const context = this; // get the current context
      timer = setTimeout(() => {
        fn.apply(context, args); // call the function if time expires
      }, wait);
    };
  },

  groupID(d) {
    return decodeURI(d)
      .split("/")
      .filter((x) => x);
  },

  expand,
  convertToMinutes,


};


export const checkedRequiredDepartment = (that, departments) => {

  if (!that) {
    return false;
  }
  if (!Array.isArray(departments)) {
    departments = [departments];
  }
  let intersection = departments.filter((x) =>
    that.$store.getters.currentUserDetails?.["DepartmentIds"]?.includes(x)
  );
  if (!intersection || intersection.length == 0) {
    return false;
  }
  return true;
}


export const chunk = (inputArray, chunkSize = 15, pad = undefined) => {


  const rtn = Array.from(
    { length: Math.ceil(inputArray.length / chunkSize) },
    (arrayValues, arrayIndex) =>
      inputArray.slice(
        arrayIndex * chunkSize,
        arrayIndex * chunkSize + chunkSize
      )
  );

  if (pad === undefined) {
    return rtn;
  }

  const count = rtn.length

  if (count === 0) {
    return rtn;
  }

  while (rtn[count - 1].length < chunkSize) {
    rtn[count - 1].push(pad);
  }

  return rtn
}



const arrayComparisons = (leftArray, rightArray) => {




  return {
    leftOnly: leftArray.filter(
      (filterInput) => !rightArray.includes(filterInput)
    ),
    rightOnly: rightArray.filter(
      (filterInput) => !leftArray.includes(filterInput)
    ),
    overlap: leftArray.filter((filterInput) =>
      rightArray.includes(filterInput)
    ),
  }
}

export const objectKeysChanged = (
  leftObject,
  rightObject,
  depth = 1
) => {
  let cDiff = arrayComparisons(
    Object.keys(leftObject ?? {}),
    Object.keys(rightObject ?? {})
  )

  let deletes = cDiff.leftOnly
  let updates = cDiff.rightOnly

  for (let keyValues of cDiff.overlap) {
    if (!objectsTheSame(leftObject[keyValues], rightObject[keyValues], depth)) {
      updates.push(keyValues)
    }
  }

  if (deletes.length > 0 || updates.length > 0) {
    return { deletes, updates }
  }
  return false
}

export const routeEndsWith = (route, y) => {

  console.log({ route: route.path, y })
  const rtn = (
    route.path.endsWith("/" + y) ||
    route.path.endsWith("/" + y + "/")
  )
  console.log({ route: route.path, y, rtn })
  return rtn
}
export const objectsTheSame = (leftObject, rightObject, depth = 1) => {

  //console.log({ comparing: { leftObject: JSON.stringify(leftObject), rightObject: JSON.stringify(rightObject) } })

  const leftType = typeof leftObject

  if (leftType != typeof rightObject) {
    console.log("Different types", { leftType, rightType: typeof rightObject })
    return false
  }

  if (["undefined", "null"].includes(leftType)) {
    return true
  }


  if (leftType != 'object') {
    if (leftType == "string") {
      console.log("Comparing strings", leftObject, rightObject)
      return leftObject == rightObject
    }

    if (leftType == "number") {
      console.log("Comparing numbers", leftObject, rightObject)
      return leftObject === rightObject
    }

    console.log({ leftObject: leftType, rightObject: typeof rightObject })
    if (["undefined", "null"].includes(leftType)) {
      return false;
    }
    throw 'Unable to compare'
  }



  let leftObjectKeys = Object.keys(leftObject)
  let rightObjectKeys = Object.keys(rightObject)





  let comparisonResult = arrayComparisons(leftObjectKeys, rightObjectKeys)

  if (comparisonResult.leftOnly.length || comparisonResult.rightOnly.length) {
    return false
  }

  for (let overlapValues of comparisonResult.overlap) {

    if (typeof leftObject[overlapValues] != typeof rightObject[overlapValues]) {
      return false
    }

    if (
      typeof leftObject[overlapValues] == 'object' &&
      leftObject[overlapValues] &&
      rightObject[overlapValues]
    ) {
      if (depth > 0) {
        if (
          !objectsTheSame(
            leftObject[overlapValues],
            rightObject[overlapValues],
            depth - 1
          )
        ) {
          return false
        }
      }
    } else {
      if (leftObject[overlapValues] !== rightObject[overlapValues]) {
        console.log("Different values", { left: leftObject[overlapValues], right: rightObject[overlapValues], overlapValues })
        return false
      }
    }
  }
  return true
}
