import { make } from 'vuex-pathify'


const state = {
  currentList: [],
  currentAssessmentData: {},
  activeNumber: 0,
  activeLastRetrieved: null

}



export default {

  namespaced: true,


  state,
  actions: make.actions(state),
  mutations: {
    ...make.mutations(state)
  },
  getters: {
    ...make.getters(state),

    hasRequest: (state) => {
      return (state.activeNumber ?? 0) > 0;
    },


  }







}





