var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentType,_vm._b({tag:"component",staticClass:"maz-base-component maz-btn maz-inline-flex",class:[
        _vm.classes,
        {
            'maz-text-hidden': _vm.loading
        }
    ],attrs:{"id":_vm.uniqueId,"type":_vm.isLink ? null : _vm.type,"disabled":_vm.isLink ? null : _vm.isDisabled},on:{"click":function($event){return _vm.handleClick($event)},"mouseenter":function($event){return _vm.emitMouseEnter($event)},"mouseleave":function($event){return _vm.emitMouseLeave($event)},"focus":function($event){return _vm.emitFocus($event)},"blur":function($event){return _vm.emitBlur($event)}}},'component',_vm.$attrs,false),[(_vm.hasLeftIcon() || _vm.hasMainIcon())?_c('div',{staticClass:"maz-flex maz-flex-center maz-btn__icon-left",class:{
        'maz-mr-2': !_vm.fab && _vm.hasSlotDefault()
    }},[_vm._t(`icon-left` || `icon`,function(){return [_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.leftIconName || _vm.iconName))])]})],2):_vm._e(),_c('span',{staticClass:"maz-flex maz-align-center maz-h-100 maz-overflow-hidden",class:[_vm.textClasses, { 'maz-flex-1': _vm.hasSlotDefault() }]},[_vm._t("default")],2),(_vm.hasRightIcon())?_c('div',{staticClass:"maz-flex maz-flex-center maz-btn__icon-right",class:{
        'maz-ml-2': !_vm.fab && _vm.hasSlotDefault()
    }},[_vm._t(`icon-right`,function(){return [_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.rightIconName))])]})],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"maz-btn__spinner maz-flex maz-flex-center"},[_c('MhSpinner',{attrs:{"size":25,"color":_vm.color}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }